<template>
  <div class="hih-map">
    <v-dialog
      v-model="mapShow"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-app-bar
          dark
          fixed
          elevation="1"
          color="purple"
        >
          <v-btn
            icon
            dark
            @click="$store.commit('MAP', {
              show: false,
              data: null
            })"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Map</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn fab icon depressed color="white" light @click.prevent="ReloadMap()">
              <v-icon>
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              v-if="$store.state.map.setPoint.draggable"
              dark
              text
              @click="$store.commit('MAP', {
                show: false,
                setPoint: {
                  active: $store.state.map.setPoint.active,
                  data: $store.state.map.setPoint.data || [-7.1690149, 112.6612242]
                },
                data: null
              })"
            >
              Set Location
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
        <v-card class="pt-14 pt-md-16 px-0 pb-0" elevation="0" flat>
          <v-card-text v-if="map.show" class="pa-0" :style="{ height: $store.state.map.setPoint.draggable ? 'calc(100vh - 89px)' : 'calc(100vh - 64px)' }">
            <vue-google-autocomplete
              v-if="$store.state.map.setPoint.draggable"
              id="mapSearch"
              ref="mapSearch"
              placeholder="Search ..."
              v-on:placechanged="getPlaceData"
              country="id"
            />
            <l-map
              id="map"
              ref="map"
              :zoom="map.zoom"
              :center="map.center"
              :options="map.mapOptions"
              style="height: 100%;width: 100%"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              @update:bounds="boundsUpdate"
              @ready="MapReady"
            >
              <l-control-fullscreen />
              <l-tile-layer
                :url="map.url"
                :attribution="map.attribution"
              />
              <l-marker
                v-if="!$store.state.map.setPoint.active"
                :lat-lng="[-7.1690149, 112.6612242]"
                :icon="mapIcon"
              >
                <l-tooltip :options="{ permanent: true, interactive: true }">
                  <div>
                    {{ $store.state.map.data.driver_name || 'GMJ Driver' }}<br>
                    Phone: {{ $store.state.map.data.driver_phone || '-' }}
                  </div>
                </l-tooltip>
              </l-marker>
              <l-marker
                v-else
                :draggable="$store.state.map.setPoint.draggable"
                @update:latLng="setPointCoordinates"
                :icon="mapIcon"
                :lat-lng="$store.state.map.setPoint.data || [-7.1690149, 112.6612242]"
              >
                <l-tooltip :options="{ permanent: true, interactive: true }">
                  <div>
                    <strong>{{ $store.state.map.data.driver_name || 'GMJ Driver' }}</strong><br>
                    Phone: +{{ $store.state.map.data.driver_phone || '-' }}<br>
                    <div class="d-flex align-center pr-2 pt-1">
                      <v-btn
                        v-if="$store.state.map.data.driver_phone"
                        :href="'https://api.whatsapp.com/send?phone=' + $store.state.map.data.driver_phone + '&text=Tanya%20posisi...'"
                        target="_blank"
                        x-small
                        color="green"
                        dark
                        depressed
                        class="mr-1 text-capitalize rounded-pill white--text"
                      >
                        <v-icon x-small class="mr-1">
                          mdi-whatsapp
                        </v-icon>
                        Whatsapp
                      </v-btn>
                      <v-btn
                        v-if="$store.state.map.data.driver_phone"
                        :href="'tel:' + $store.state.map.data.driver_phone"
                        target="_blank"
                        x-small
                        color="blue"
                        dark
                        depressed
                        class="text-capitalize rounded-pill white--text"
                      >
                        <v-icon x-small class="mr-1">
                          mdi-phone
                        </v-icon>
                        Call
                      </v-btn>
                    </div>
                  </div>
                </l-tooltip>
              </l-marker>
            </l-map>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'
import { mapState } from 'vuex'
import LControlFullscreen from 'vue2-leaflet-fullscreen'
// import VueGoogleAutocomplete from 'vue-google-autocomplete'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'MyAwesomeMap',
  props: {
    latlng: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LControlFullscreen,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data: () => ({
    mapIcon: L.icon({
      iconUrl: '/images/driver.png',
      iconSize: [30, 50],
      iconAnchor: [10, 50]
    }),
    map: {
      show: false,
      zoom: 15,
      center: L.latLng(-7.1690149, 112.6612242),
      fillColor: '#e4ce7f',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 15,
      currentCenter: L.latLng(-7.1690149, 112.6612242),
      mapOptions: {
        zoomSnap: 0.5
      }
    }
  }),
  computed: {
    ...mapState({
      mapShow: state => state.map.show
    })
  },
  watch: {
    mapShow: function (v) {
      if (v) {
        if (this.$store.state.map.setPoint.data) {
          this.map.center = L.latLng(this.$store.state.map.setPoint.data[0], this.$store.state.map.setPoint.data[1])
          this.map.currentCenter = L.latLng(this.$store.state.map.setPoint.data[0], this.$store.state.map.setPoint.data[1])
        } else if (this.latlng.length) {
          this.map.center = L.latLng(this.latlng[0], this.latlng[1])
          this.map.currentCenter = L.latLng(this.latlng[0], this.latlng[1])
        } else {
          this.map.center = L.latLng(-7.1690149, 112.6612242)
          this.map.currentCenter = L.latLng(-7.1690149, 112.6612242)
        }
        setTimeout(() => {
          this.drawMap()
        }, 300)
      } else {
        this.map.show = false
      }
    }
  },
  mounted () {
  },
  destroyed () {
    this.map.show = false
  },
  methods: {
    ReloadMap () {
      const trxID = parseInt(this.$store.state.map.data.id) || 0
      if (trxID) {
        this.$store.dispatch('trx/TRX_GET_ONE', trxID)
          .then((res) => {
            if (res.status) {
              this.$store.commit('MAP', {
                show: true,
                setPoint: {
                  active: true,
                  data: (res.data.data.driver_lat && res.data.data.driver_lng ? [parseFloat(res.data.data.driver_lat), parseFloat(res.data.data.driver_lng)] : null)
                },
                data: Object.assign({}, this.$store.state.map.data)
              })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            }
          })
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Transaction ID!' })
      }
    },
    getPlaceData (v) {
      if (v.route) {
        this.$store.commit('MAP', {
          show: true,
          setPoint: {
            active: true,
            draggable: true,
            data: [v.latitude, v.longitude]
          },
          data: null
        })
        this.map.center = L.latLng(v.latitude, v.longitude)
      }
    },
    MapReady () {
      console.log('ready')
    },
    SetLatLong (lat, long) {
      return L.latLng(lat, long)
    },
    drawMap () {
      this.map.show = true
    },
    zoomUpdate (zoom) {
      this.map.currentZoom = zoom
    },
    centerUpdate (center) {
      this.map.currentCenter = center
    },
    boundsUpdate (bounds) {
    },
    setPointCoordinates (latlng) {
      const s = document.getElementById('mapSearch')
      if (s) {
        s.value = ''
      }
      this.$store.commit('MAP', {
        show: true,
        setPoint: {
          active: true,
          draggable: true,
          data: [latlng.lat, latlng.lng]
        },
        data: null
      })
      this.map.center = latlng
    }
  }
}
</script>

<style lang="scss">
#mapSearch {
  display: block;
  width: 100%;
  padding: 5px 15px;
  outline: none;
}
</style>
